// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.not-found-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(135deg, #143c5b 0%, #2a5298 100%);
  color: white;
  text-align: center;
  padding: 20px;
}

.not-found-title {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.not-found-subtitle {
  font-size: 1.25rem;
  margin-bottom: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/not-found/not-found.css"],"names":[],"mappings":"AAAA;EACE,OAAO;EACP,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,6DAA6D;EAC7D,YAAY;EACZ,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;AACrB","sourcesContent":[".not-found-container {\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  height: 100vh;\n  background: linear-gradient(135deg, #143c5b 0%, #2a5298 100%);\n  color: white;\n  text-align: center;\n  padding: 20px;\n}\n\n.not-found-title {\n  font-size: 2.5rem;\n  margin-bottom: 20px;\n}\n\n.not-found-subtitle {\n  font-size: 1.25rem;\n  margin-bottom: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
