import React from 'react'
import './not-found.css'

const NotFound = (): React.JSX.Element => {

  return (
    <main className='not-found-container'>
      <h1 className='not-found-title'>Página não encontrada</h1>
      <p className='not-found-subtitle'>
        Não conseguimos encontrar esta página
      </p>
    </main>
  )
}
export default NotFound