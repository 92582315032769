// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  background-color: #333;
  color: white;
  padding: 20px;
  display: flex;
  flex-direction: row;
}

.social-icons {
  margin-bottom: 10px;
  width: 50%;
  align-content: center;
  text-align: left;
}

.social-icons a {
  color: white;
  margin: 0 10px;
  font-size: 24px;
  text-decoration: none;
}

.social-icons a:hover {
  color: #ccc;
}

.company-address {
  font-size: 14px;
  line-height: 1.5;
  width: 50%;
  text-align: right;
}`, "",{"version":3,"sources":["webpack://./src/components/footer/footer.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,YAAY;EACZ,aAAa;EACb,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,UAAU;EACV,qBAAqB;EACrB,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,cAAc;EACd,eAAe;EACf,qBAAqB;AACvB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,UAAU;EACV,iBAAiB;AACnB","sourcesContent":[".footer {\n  background-color: #333;\n  color: white;\n  padding: 20px;\n  display: flex;\n  flex-direction: row;\n}\n\n.social-icons {\n  margin-bottom: 10px;\n  width: 50%;\n  align-content: center;\n  text-align: left;\n}\n\n.social-icons a {\n  color: white;\n  margin: 0 10px;\n  font-size: 24px;\n  text-decoration: none;\n}\n\n.social-icons a:hover {\n  color: #ccc;\n}\n\n.company-address {\n  font-size: 14px;\n  line-height: 1.5;\n  width: 50%;\n  text-align: right;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
