import React from 'react'
import './App.css'
import { pages } from './enum'
import { HeaderMenu, Footer } from './components'
import { Contato, HomePage, NotFound } from './pages'
import { Route, Routes, Navigate } from 'react-router-dom'

function App(): React.JSX.Element {
  return (
    <div className='App'>
      <HeaderMenu />
      <Routes>
        <Route path={pages.HOME} element={<HomePage />} />
        <Route path={pages.CONTATO} element={<Contato />} />
        <Route path={pages.NOT_FOUND} element={<NotFound />} />
        <Route path='*' element={<Navigate to={pages.NOT_FOUND} replace />} />
      </Routes>
      <Footer />
    </div>
  )
}

export default App