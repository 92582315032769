// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-menu {
  background-color: white;
  padding: 10px 20px;
  border-top: #143C5B solid 5px;
}

.header-container {
  display: flex;
  justify-content: space-between;
}

.header-image {
  width: 120px;
  height: auto;
}

.header-menu ul {
  list-style-type: none;
  display: flex;
  gap: 20px;
}

.header-menu li {
  display: inline;
}

.header-menu a {
  color: #143C5B;
  text-decoration: none;
}

.header-menu a:hover {
  text-decoration: underline;
}

.hamburger {
  display: none;
  font-size: 1.8rem;
  cursor: pointer;
}

.nav-menu {
  display: flex;
}

.nav-menu.open {
  display: block;
}

@media (max-width: 768px) {
  .hamburger {
    display: block;
  }

  .nav-menu {
    display: none;
    position: absolute;
    top: 70px;
    right: 0;
    width: 100%;
    background-color: white;
    text-align: center;
  }

  .nav-menu ul {
    flex-direction: column;
    gap: 10px;
    padding: 20px;
  }

  .nav-menu ul li a {
    font-size: 1.5rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/header/header-menu.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,kBAAkB;EAClB,6BAA6B;AAC/B;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,qBAAqB;EACrB,aAAa;EACb,SAAS;AACX;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,qBAAqB;AACvB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,aAAa;EACb,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE;IACE,cAAc;EAChB;;EAEA;IACE,aAAa;IACb,kBAAkB;IAClB,SAAS;IACT,QAAQ;IACR,WAAW;IACX,uBAAuB;IACvB,kBAAkB;EACpB;;EAEA;IACE,sBAAsB;IACtB,SAAS;IACT,aAAa;EACf;;EAEA;IACE,iBAAiB;EACnB;AACF","sourcesContent":[".header-menu {\n  background-color: white;\n  padding: 10px 20px;\n  border-top: #143C5B solid 5px;\n}\n\n.header-container {\n  display: flex;\n  justify-content: space-between;\n}\n\n.header-image {\n  width: 120px;\n  height: auto;\n}\n\n.header-menu ul {\n  list-style-type: none;\n  display: flex;\n  gap: 20px;\n}\n\n.header-menu li {\n  display: inline;\n}\n\n.header-menu a {\n  color: #143C5B;\n  text-decoration: none;\n}\n\n.header-menu a:hover {\n  text-decoration: underline;\n}\n\n.hamburger {\n  display: none;\n  font-size: 1.8rem;\n  cursor: pointer;\n}\n\n.nav-menu {\n  display: flex;\n}\n\n.nav-menu.open {\n  display: block;\n}\n\n@media (max-width: 768px) {\n  .hamburger {\n    display: block;\n  }\n\n  .nav-menu {\n    display: none;\n    position: absolute;\n    top: 70px;\n    right: 0;\n    width: 100%;\n    background-color: white;\n    text-align: center;\n  }\n\n  .nav-menu ul {\n    flex-direction: column;\n    gap: 10px;\n    padding: 20px;\n  }\n\n  .nav-menu ul li a {\n    font-size: 1.5rem;\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
