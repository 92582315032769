// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-page-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(135deg, #143C5B 0%, #2a5298 100%);
  /* background: linear-gradient(135deg, #29ba8a 0%, #7ed5b8 100%); */
  color: white;
  text-align: center;
  padding: 20px;
}

.content {
  max-width: 600px;
}

h1 {
  font-size: 3rem;
  margin-bottom: 20px;
}

.message-home-page {
  font-size: 1.5rem;
  margin-bottom: 40px;
}`, "",{"version":3,"sources":["webpack://./src/pages/home-page/home-page.css"],"names":[],"mappings":"AAAA;EACE,OAAO;EACP,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,6DAA6D;EAC7D,mEAAmE;EACnE,YAAY;EACZ,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,mBAAmB;AACrB","sourcesContent":[".home-page-container {\n  flex: 1;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100vh;\n  background: linear-gradient(135deg, #143C5B 0%, #2a5298 100%);\n  /* background: linear-gradient(135deg, #29ba8a 0%, #7ed5b8 100%); */\n  color: white;\n  text-align: center;\n  padding: 20px;\n}\n\n.content {\n  max-width: 600px;\n}\n\nh1 {\n  font-size: 3rem;\n  margin-bottom: 20px;\n}\n\n.message-home-page {\n  font-size: 1.5rem;\n  margin-bottom: 40px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
